export const awsconfig = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_auQR6A9P6",
    userPoolWebClientId: "4idi4cvjq72lsj0fut1as9as7m",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  aws_appsync_region: "us-east-1",
  aws_appsync_graphqlEndpoint:
    "https://qhldjt2zwrexhmkfs3tf2odlwu.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-6mqcwpcc2vdsloijrk32gcryii",
};